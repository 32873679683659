<template>
    <v-dialog v-model="isOpen" width="50%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Annuler un rendez-vous
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="mt-2">
                Êtes-vous sûr de vouloir annuler ce rendez-vous ?
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit(true)" color="primary" depressed small dark>
                    Annuler par le promoteur
                </v-btn>

                <v-btn @click="submit(false)" color="primary" depressed small dark>
                    Annuler par l'acquéreur
                </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" depressed small>
                    Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'CancelAppointmentAdminDialog',

    props: {
        appointment: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false
    }),

    methods: {
        async submit(user) {
            try {
                this.setLoading(true);

                if (user) {
                    const { success, err } = await this.repos.appointments.cancelAppointmentUser(this.appointment.instance.id);
                    if (success) {
                        this.$nextTick(() => {
                            this.$emit('canceled');
                        });
                        this.isOpen = false;
                    } else {
                        throw new Error(err);
                    }
                } else {
                    const { success, err } = await this.repos.appointments.cancelAppointmentBuyer(this.appointment.instance.id);
                    if (success) {
                        this.$nextTick(() => {
                            this.$emit('canceled');
                        });
                        this.isOpen = false;
                    } else {
                        throw new Error(err);
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression du rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>