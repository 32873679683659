<template>
    <v-dialog v-model="isOpen" width="1200px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Rendez-vous acquéreur
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p>
                    Visualisez et gérez ici les rendez-vous de
                    <span v-for="(buyer, i) of sale.buyers" :key="buyer.id + '_' + buyer.type" class="blue--text">{{ buyer.name }} {{ buyer.firstname }}<template v-if="i < sale.buyers.length - 1"> / </template></span>,
                    {{ sale.buyers.length | plural('acquéreur', 'acquéreurs') }} <span class="blue--text"> {{ sale.product.reference }} </span>
                    sur le programme <span class="blue--text">{{ sale.program.title }}</span>.
                </p>

                <div class="d-flex align-center">
                    <v-switch label="Afficher les rendez-vous passés" v-model="displayPastAppointments" class="mt-0" dense hide-details />

                    <v-spacer />

                    <ManageAppointmentDialog :sale="sale" @created="fetchAppointments()">
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" small v-on="on">
                                Nouveau rendez-vous
                            </v-btn>
                        </template>
                    </ManageAppointmentDialog>
                </div>

                <ValidationObserver ref="observer">
                    <v-simple-table class="rounded">
                        <template>
                            <thead>

                                <tr>
                                    <th>
                                        Object
                                    </th>

                                    <th>
                                        Date, heure, durée
                                    </th>

                                    <th>
                                        Lieu
                                    </th>

                                    <th>
                                        Intervenant / Compétences
                                    </th>

                                    <th> </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="appointment of appointments" :key="appointment.id">
                                    <td>
                                        {{ appointment.subject }} 
                                    </td>

                                    <td>
                                        {{ getAppointmentDateText(appointment) }}

                                        <template v-if="appointment.instance && appointment.instance.status === 11">
                                            <br>
                                            <span :class="getAppointmentStatusTextColor(appointment.instance.status)">
                                                (Le rendez-vous du {{ appointment.instance.startDate | toDatetime(false) }} a été annulé par l'acquéreur)
                                            </span>
                                        </template>

                                        <template v-if="appointment.instance && appointment.instance.status === 12">
                                            <br>
                                            <span :class="getAppointmentStatusTextColor(appointment.instance.status)">
                                                (Le rendez-vous du {{ appointment.instance.startDate | toDatetime(false) }} a été annulé par le promoteur)
                                            </span>
                                        </template>

                                        <template v-if="isAppointmentExpired(appointment)">
                                            <br>
                                            <v-chip label x-small>
                                                Expiré
                                            </v-chip>
                                        </template>
                                    </td>

                                    <td>
                                        {{ appointment.location }}
                                    </td>

                                    <td>
                                        {{ appointment.categories.map((c) => c.name).join(', ') }}
                                    </td>

                                    <td>
                                        <div class="d-flex">
                                            <ManageAppointmentDialog :sale="sale" :appointmentId="appointment.id" @updated="fetchAppointments()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn class="mr-1" color="app-blue" small icon v-on="{ ...tooltip, ...dialog }">
                                                                <v-icon small> fas fa-edit </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Modifier
                                                    </v-tooltip>
                                                </template>
                                            </ManageAppointmentDialog>
                                           
                                            <CancelAppointmentAdminDialog v-if="appointment.instance && [0,255].includes(appointment.instance.status)" :appointment="appointment" @canceled="fetchAppointments()">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn class="mr-1" color="red" small icon v-on="{ ...tooltip, ...dialog }">
                                                                <v-icon small> fas fa-ban </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Annuler
                                                    </v-tooltip>
                                                </template>
                                            </CancelAppointmentAdminDialog>

                                            <ConfirmationDialog v-if="!appointment.instance || ![0, 255].includes(appointment.instance.status)" text="Êtes-vous sûr de vouloir supprimer cette proposition rendez-vous ?" @confirm="deleteAppointment(appointment)">
                                                <template v-slot:activator="{ on: dialog }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn class="mr-1" color="red" small icon v-on="{ ...tooltip, ...dialog }">
                                                                <v-icon small> fas fa-trash-alt </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Supprimer
                                                    </v-tooltip>
                                                </template>
                                            </ConfirmationDialog>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="appointments.length === 0">
                                    <td colspan="5" class="text-center grey--text">
                                        Aucun rendez-vous
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { format, isBefore } from 'date-fns';
import { fr } from 'date-fns/locale';

import appointmentsMixin from '../../mixins/appointments.js';
import ConfirmationDialog from '../dialogs/ConfirmationDialog.vue';
import ManageAppointmentDialog from './ManageAppointmentDialog.vue';
import CancelAppointmentAdminDialog from './CancelAppointmentAdminDialog.vue';

export default {
    name: 'ManageBuyerAppointmentDialog',

    mixins: [appointmentsMixin],

    components: {
        ConfirmationDialog,
        ManageAppointmentDialog,
        CancelAppointmentAdminDialog
    },

    props: {
        sale: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false,
        displayPastAppointments: false,

        appointments: []
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.fetchAppointments();
            }
        },

        displayPastAppointments() {
            this.fetchAppointments();
        }
    },

    methods: {
        async fetchAppointments() {
            try {
                this.setLoading(true);

                const query = {
                    include: 'programs,categories',
                    sales: [this.sale.id]
                };

                if (!this.displayPastAppointments) {
                    query.hidePastAppointments = '1';
                }

                const { appointments, err } = await this.repos.appointments.getAppointments(query);
                if (err) {
                    throw new Error(err);
                }
                this.appointments = appointments;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async deleteAppointment(appointment) {
            try {
                this.setLoading(true);

                const { success, err } = await this.repos.appointments.deleteAppointment(appointment.id);
                if (err) {
                    throw new Error(err);
                }
                
                if (success) {
                    this.fetchAppointments();
                    this.$notify({
                        title: 'Succès',
                        text: 'Le rendez-vous a bien été supprimé',
                        type: 'success'
                    });
                }                
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression du rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        getAppointmentDateText(appointment) {
            switch (appointment.instance.status) {
                case 255:
                    return 'Rendez-vous réalisé le ' + format(new Date(appointment.instance.startDate), 'dd/MM/yyyy', { locale: fr });
                case 0:
                    return 'Rendez-vous à venir le ' + format(new Date(appointment.instance.startDate), 'dd/MM/yyyy', { locale: fr });
                case 11:
                case 12:
                case null:
                    return 'Rendez-vous à programmer avant le ' + format(new Date(appointment.maxDate), 'dd/MM/yyyy', { locale: fr });
            }
        },

        isAppointmentExpired(appointment) {
            return isBefore(new Date(appointment.maxDate), new Date());
        }
    }
};
</script>
