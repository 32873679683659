<template>
    <v-dialog v-model="isOpen" width="900px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    <template v-if="canUpdateInstance">
                        Rendez-vous à planifier
                    </template>
                    <template v-else>
                        Modification d'un rendez-vous
                    </template>
                    
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <p v-if="canUpdateInstance">
                    Créez ici un rendez-vous à planifier pour
                    <span v-for="(buyer, i) of sale.buyers" :key="buyer.id + '_' + buyer.type" class="blue--text">{{ buyer.name }} {{ buyer.firstname }}<template v-if="i < sale.buyers.length - 1"> / </template></span>,
                    {{ sale.buyers.length | plural('acquéreur', 'acquéreurs') }} du lot <span class="blue--text"> {{ sale.product.reference }} </span>
                    sur le programme <span class="blue--text">{{ sale.program.title }}</span>.
                </p>
                <p v-else>
                    Modifiez ici le rendez-vous planifié pour
                    <span v-for="(buyer, i) of sale.buyers" :key="buyer.id + '_' + buyer.type" class="blue--text">{{ buyer.name }} {{ buyer.firstname }}<template v-if="i < sale.buyers.length - 1"> / </template></span>,
                    {{ sale.buyers.length | plural('acquéreur', 'acquéreurs') }} du lot <span class="blue--text"> {{ sale.product.reference }} </span>
                    sur le programme <span class="blue--text">{{ sale.program.title }}</span>.
                </p>

                <ValidationObserver ref="observer">
                    <table style="border-spacing: 10px; width: 100%">
                        <tr>
                            <td>
                                Modèle de rendez-vous
                            </td>

                            <td colspan="2">
                                <AppointmentDefaultAutocomplete ref="appointmentDefaultAutocomplete" v-model="defaultAppointment" noDynamicSearch />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Objet <span class="red--text">*</span>
                            </td>

                            <td colspan="2">
                                <ValidationProvider v-slot="{ errors, failed }" name="Objet" rules="required">
                                    <v-text-field v-model="appointment.subject" label="Objet" :error-messages="errors" :hide-details="!failed" outlined dense />
                                </ValidationProvider>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span>
                                    Plage de dates proposée <span class="red--text">*</span>
                                </span>
                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="De" rules="required">
                                    <DatePickerMenu v-model="appointment.minDate" classes="flex-grow-0" label="De" :disabled="!canUpdateInstance" :errors="errors" :failed="failed" />
                                </ValidationProvider>
                            </td>

                            <td>
                                <ValidationProvider v-slot="{ errors, failed }" name="A" rules="required">
                                    <DatePickerMenu v-model="appointment.maxDate" classes="flex-grow-0" label="A" :disabled="!canUpdateInstance" :errors="errors" :failed="failed" />
                                </ValidationProvider>
                            </td>
                        </tr>

                        <tr v-if="appointment.instance && appointment.instance.status === 0">
                            <td>
                                <span>
                                    Date du RDV planifié
                                </span>
                            </td>

                            <td>
                                <v-text-field :value="appointment.instance.startDate | toDatetime()" classes="flex-grow-0" outlined dense hide-details label="De" disabled />
                            </td>

                            <td>
                                <v-text-field :value="appointment.instance.endDate | toDatetime()" classes="flex-grow-0" outlined dense hide-details label="A" disabled />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Durée estimée <span class="red--text">*</span>
                            </td>

                            <td colspan="2">
                                <ValidationProvider v-slot="{ errors, failed }" name="Durée estimée" rules="required">
                                    <v-select v-model="appointment.duration" :items="durationItems" label="Durée estimée" :disabled="!canUpdateInstance" :menu-props="{ bottom: true, offsetY: true }" :error-messages="errors" :hide-details="!failed" outlined dense />
                                </ValidationProvider>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Lieu
                            </td>

                            <td colspan="2">
                                <v-text-field v-model="appointment.location" label="Lieu" hide-details outlined dense />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Quelles sont les domaines de compétence requis pour ce rendez-vous?
                            </td>

                            <td colspan="2">
                                <AppointmentCategoryAutocomplete ref="appointmentCategoryAutocomplete" v-model="appointment.categoryIds" :disabled="!canUpdateInstance" noDynamicSearch multiple />
                            </td>
                        </tr>

                        <tr v-if="isCreation">
                            <td class="px-2" colspan="3">
                                <v-switch v-model="appointment.duplicateForProgramSales" small label="Prévoir le même rendez-vous pour tous les acquéreurs du programme" />
                            </td>
                        </tr>
                    </table>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { format, endOfDay } from 'date-fns';
import DatePickerMenu from '../widgets/DatePickerMenu.vue';
import AppointmentDefaultAutocomplete from '../widgets/AppointmentDefaultAutocomplete.vue';
import AppointmentCategoryAutocomplete from '../widgets/AppointmentCategoryAutocomplete.vue';

export default {
    name: 'ManageAppointmentDialog',

    components: {
        DatePickerMenu,
        AppointmentDefaultAutocomplete,
        AppointmentCategoryAutocomplete
    },

    props: {
        sale: { type: Object, required: true },
        appointmentId: { type: Number },
        initAppointment: { type: Object }
    },

    data: () => ({
        isOpen: false,
        additionalDataFetched: false,

        appointment: {},
        defaultAppointment: null,

        durationItems: [
            { text: '30min', value: 30 },
            { text: '1h', value: 60 },
            { text: '1h30', value: 90 },
            { text: '2h', value: 120 },
            { text: '2h30', value: 150 },
            { text: '3h', value: 180 },
            { text: '3h30', value: 210 },
            { text: '4h', value: 240 },
            { text: '4h30', value: 270 },
            { text: '5h', value: 300 },
            { text: '5h30', value: 330 },
            { text: '6h', value: 360 }
        ]
    }),

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.appointment = {
                    duration: 30
                };
                if (this.appointmentId) {
                    this.fetchAppointment(this.appointmentId);
                }

                this.defaultAppointment = null;

                this.$nextTick(() => {
                    this.$refs.observer.reset();
                    if (!this.additionalDataFetched) {
                        this.additionalDataFetched = true;
                        this.$refs.appointmentCategoryAutocomplete.fetchCategories({ limit: 10000 });
                        this.$refs.appointmentDefaultAutocomplete.fetch({ limit: 10000 });
                    }
                });
            }
        },

        defaultAppointment() {
            if (this.defaultAppointment) {
                this.appointment.defaultId = this.defaultAppointment.id;
                this.appointment.subject = this.defaultAppointment.subject;
                this.appointment.duration = this.defaultAppointment.duration;
                this.appointment.location = this.defaultAppointment.location;
                this.appointment.categories = this.defaultAppointment.categories.map((c) => c.id);
            }
        }
    },

    computed: {
        canUpdateInstance () {
            return !this.appointment.instance ||
                ![0, 255].includes(this.appointment.instance.status);
        },

        isCreation() {
            return typeof this.appointmentId !== 'number';
        }
    },

    methods: {
        async fetchAppointment(appointmentId) {
            try {
                this.setLoading(true);

                const query = {
                    include: 'programs,categories',
                    appointments: [appointmentId]
                };

                const { appointments: [appointment], err } = await this.repos.appointments.getAppointments(query);
                if (err) {
                    throw new Error(err);
                }
                this.appointment = appointment;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement du rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        submit() {
            if (this.isCreation) {
                this.create();
            } else {
                this.update();
            }
        },

        async create() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                if (this.appointment.maxDate) {
                    this.appointment.maxDate = format(endOfDay(new Date(this.appointment.maxDate)), 'yyyy-MM-dd HH:mm:ss');
                }

                const body = { appointment: this.appointment };

                body.appointment.saleId = this.sale.id;

                const { success, err } = await this.repos.appointments.createAppointment(body);
                if (success) {
                    this.isOpen = false;
                    this.$notify({
                        title: 'Succès',
                        text: 'Le nouveau rendez-vous à bien été créé',
                        type: 'success'
                    });
                    this.$emit('created');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création d\'un nouveau rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async update() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                if (this.appointment.maxDate) {
                    this.appointment.maxDate = format(endOfDay(new Date(this.appointment.maxDate)), 'yyyy-MM-dd HH:mm:ss');
                }

                const body = { appointment: this.appointment };

                const { success, err } = await this.repos.appointments.updateAppointment(this.appointmentId, body);
                if (success) {
                    this.isOpen = false;
                    this.$notify({
                        title: 'Succès',
                        text: 'Le rendez-vous à bien été mis à jour',
                        type: 'success'
                    });
                    this.$emit('updated');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la modification du rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.asset-card {
    width: 160px;
    height: 160px;
}
</style>